/**
 * Table of Contents
 *
 * Automatically generate a table of contents from the headings on the page.
 *
 * @param  {String} content A selector for the element that the content is in
 * @param  {String} target  A selector for the container to render the table of contents into
 * @param  {Object} options An object of user options [optional]
 */

const tableOfContents = function (content, target, options) {
    window.isTocReady = false;
    let tocScope = document.querySelector(content);
    let toc = document.querySelector(target);
    let headings;
    let headingsMap = {};
    let settings = {};

    if (!tocScope || !toc) {
        console.log(
            'TOC: No selectors found for the TOC’s scope and/or its container. Abort.'
        );
        return;
    }

    // console.log('TOC: Start building...');

    const defaultSettings = {
        tocListClass: 'c-toc__list',
        tocItemClass: 'c-toc__item',
        tocLinkClass: 'c-toc__link',
        tocListType: 'ol',
        levels: 'h2, h3',
    };

    /**
     * Collect all the IDs used throughout the page.
     * We can check against this collection in order
     * to prevent generating a dublicate IDs.
     * @param  {Map} idMap
     */
    var collectIds = function (idMap) {
        let elemenstWithId = document.querySelectorAll('[id]');
        return Array.prototype.map.call(
            elemenstWithId,
            function (elementWithId) {
                let id = elementWithId.id;
                idMap[id] = !isNaN(idMap[id]) ? ++idMap[id] : 0;
            }
        );
    };

    /**
     * Merge user options into defaults.
     *
     * @param  {Object} obj The user options
     */
    const merge = function (obj) {
        for (let key in defaultSettings) {
            if (Object.prototype.hasOwnProperty.call(defaultSettings, key)) {
                settings[key] = Object.prototype.hasOwnProperty.call(obj, key)
                    ? obj[key]
                    : defaultSettings[key];
            }
        }
    };

    var truncate = function (text, maxLength) {
        const ellipsis = '…';
        return text.length > maxLength - ellipsis.length
            ? text.substring(0, maxLength - ellipsis.length) + ellipsis
            : text;
    };

    /**
     * Generate a slug from a string of text or generate
     * a random one if no chars are slugify-able.
     *
     * @param {String} text - The heading’s text content to generate the slug from
     */
    const slugify = function (text) {
        let slug = encodeURIComponent(
            text
                .toString()
                .trim()
                .toLowerCase()
                .replace(/&/g, 'und') // Replace `&` with `und`
                .replace(/–/g, '-') // Replace en dash with dash
                .replace(/—/g, '-') // Replace em dash with dash
                .replace(/ /g, '-') // Replace space with dash
                .replace(/-{2,}/g, '-') // Consolidate consecutive dashes
                .replace(/[^a-zA-Z0-9 -]/g, '')
                .replace(/^-+/, '') // Trim dash from start
                .replace(/-+$/, '') // Trim dash from end
        );
        truncate(slug, 40);
        return slug.length
            ? slug
            : (slug = Math.random().toString(16).slice(2));
    };

    /**
     * Get the HTML to indent a list a specific number of levels
     *
     * @param  {Integer} count The number of times to indent the list
     * @return {String} The HTML
     */
    var getIndent = function (count) {
        var html = '';
        for (var i = 0; i < count; i++) {
            html += '<' + settings.tocListType + ' class="o-grid">';
        }
        return html;
    };

    /**
     * Get the HTML to close an indented list a specific number of levels
     *
     * @param  {Integer} count - The number of times to "outdent" the list
     * @return {String} The HTML
     */
    var getOutdent = function (count) {
        var html = '';
        for (var i = 0; i < count; i++) {
            html += '</' + settings.tocListType + '></li>';
        }
        return html;
    };

    /**
     * Get the HTML string to start a new list of headings
     *
     * @param  {Integer} diff - The number of levels in or out from the current level the list is
     * @param  {Integer} index - The index of the heading in the "headings" NodeList
     * @return {String} The HTML
     */
    var getStartingHTML = function (diff, index) {
        // If indenting
        if (diff > 0) {
            return getIndent(diff);
        }

        // If outdenting
        if (diff < 0) {
            return getOutdent(Math.abs(diff));
        }

        // If it's not the first item and there's no difference
        if (index && !diff) {
            return '</li>';
        }

        return '';
    };

    /**
     * Inject the toc’s HTML into the DOM
     */
    var injectToc = function () {
        // console.log('TOC: Start injecting...');

        // Get the current heading level
        var headingLevel = headings[0].tagName.slice(1);
        var startingLevel = headingLevel;

        // Cache the number of headings
        var len = headings.length - 1;

        // Create the HTML
        var tocHTML =
            '<' +
            settings.tocListType +
            ' class="' +
            settings.tocListClass +
            '">' +
            Array.prototype.map
                .call(headings, function (heading, index) {
                    //
                    // Add an ID if none is present
                    let id = heading.id
                        ? heading.id
                        : slugify(heading.innerText);

                    // Append an index if same ID already exists
                    headingsMap[id] = !isNaN(headingsMap[id])
                        ? ++headingsMap[id]
                        : 0;

                    if (headingsMap[id]) {
                        heading.id = id + '-' + headingsMap[id];
                    } else {
                        heading.id = id;
                    }

                    // Compare the heading level vs. the current list level
                    let currentLevel = heading.tagName.slice(1);
                    let levelDifference = currentLevel - headingLevel;

                    headingLevel = currentLevel;

                    let HTML = getStartingHTML(levelDifference, index);

                    // Generate the list HTML
                    HTML +=
                        '<li class="' +
                        settings.tocItemClass +
                        '">' +
                        '<a href="' +
                        '#' +
                        heading.id +
                        '" class="' +
                        settings.tocLinkClass +
                        '">' +
                        '<span class="c-icon-link__icon"><svg class="c-icon" aria-hidden="true" focusable="false"><use xlink:href="/assets/icons/sprite.svg#arrow-down-24"></use></svg></span><span class="c-icon-link__content"><span class="c-icon-link__title">' +
                        heading.innerText.trim() +
                        '</span></span></a>';

                    // If the last item, close it
                    if (index === len) {
                        HTML += getOutdent(
                            Math.abs(startingLevel - currentLevel)
                        );
                    }

                    return HTML;
                })
                .join('') +
            '</' +
            settings.tocListType +
            '>';

        // Inject the TOC’s HTML into the DOM
        toc.innerHTML = tocHTML;

        // Let others know when the TOC is built
        window.isTocReady = true;
        // console.log('TOC is ready');
    };

    /**
     * Initialize the TOC
     */
    var init = function () {
        merge(options || {});

        collectIds(headingsMap);

        // Get all heading within the scope candidates
        headings = tocScope.querySelectorAll(settings.levels);
        if (!headings.length) {
            console.log('No headings found!');
            return;
        }

        injectToc();
    };

    init();
};

export default tableOfContents;
